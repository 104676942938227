/* AddOrder.css */

.actions-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.terms-column {
  width: 80px; /* Adjust the width as needed */
}

.approval-status-column {
  width: 100px; /* Adjust the width as needed */
}

