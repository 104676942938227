.pending-actions-dashboard {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .pending-actions-dashboard .ant-card-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .pending-actions-dashboard .ant-table-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .pending-actions-dashboard .ant-table {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .pending-actions-dashboard .ant-table-tbody {
    flex-grow: 1;
    overflow-y: auto;
    height: calc(100vh - 200px); /* Adjust the 200px value based on the heights of other elements */
  }
  
  .pending-actions-dashboard .ant-pagination {
    margin: 16px 0;
    display: flex;
    justify-content: center;
  }
  