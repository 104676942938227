/* AddSchedule.css */

tfoot td {
  font-weight: bold;
  background-color: #f2f2f2;
}

.total-amounts {
  margin-bottom: 20px;
}

.required {
  color: red;
}

.table-container-add-schedule {
  margin-bottom: 0px !important;
}