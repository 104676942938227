/* src/components/features/FeatureTemplate.css */
.feature-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .feature-container h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .feature-container p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .screenshots {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .screenshots img {
    max-width: 100%;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .navigation a {
    color: #007bff;
    text-decoration: none;
  }
  
  .navigation a:hover {
    text-decoration: underline;
  }
  