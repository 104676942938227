/* src/components/PaymentWizard/PaymentWizard.css */

.payment-wizard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.breadcrumb {
  display: flex;
  padding: 10px;
  background: #f0f0f0;
  position: sticky;
  top: 0;
  z-index: 10;
}

.breadcrumb-item {
  margin-right: 5px;
}

.breadcrumb-separator {
  margin-right: 5px;
}

.breadcrumb-item.active {
  font-weight: bold;
  color: #1890ff;
}

.payment-wizard-content {
  display: flex;
  flex-direction: column;
  padding: 0 0px;
  overflow-y: auto;
  height: 100%;
}

.payment-wizard-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.payment-progress {
  display: flex;
}

.payment-progress-container {
  background: #f0f0f0;
  padding: 10px;
  border-top: 1px solid #ddd;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.payment-progress-table {
  width: 100%;
  border-collapse: collapse;
}

.payment-progress-table th,
.payment-progress-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.payment-progress-table th {
  background-color: #333;
  color: white;
}

.payment-wizard-navigation {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #f9f9f9;
  border-top: 1px solid #ddd;
}

.selected-client {
  margin-top: 10px;
  font-weight: bold;
}

/* AddLineItems.css */

.line-items-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.line-items-table th,
.line-items-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.line-items-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.total-amount {
  font-weight: bold;
  margin-top: 10px;
}

.button-group {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.payment-wizard-navigation button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s;
}

.payment-wizard-navigation button:hover {
  background-color: #0056b3;
}
