/* InvoiceDetails.css */

.invoice-details {
    margin: 20px;
  }
  
  .invoice-details h1 {
    font-size: 24px;
  }
  
  .invoice-details p {
    margin: 10px 0;
  }
  
  .invoice-details button {
    margin-top: 20px;
  }
  