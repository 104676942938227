/* Pending Action Modal CSS */
.pending-action-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .pending-action-modal-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    height: calc(100vh - 60px);
    position: relative;
    padding: 0;
    overflow: hidden;
  }
  
  .pending-action-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    background: #f1f1f1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .pending-action-modal-header h1 {
    font-size: 18px;
    margin: 0;
  }
  
  .pending-action-close-button {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pending-action-close-button:hover {
    background-color: #e81123;
    color: #fff;
  }
  
  .pending-action-close-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 120, 215, 0.5);
  }
  
  .pending-action-modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  
  .pending-action-form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 10px 20px;
  }
  
  .pending-action-form > div.full-width {
    grid-column: 1 / -1;
  }
  
  .pending-action-form > div.half-width {
    grid-column: span 1;
  }
  
  @media (min-width: 600px) {
    .pending-action-form {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .pending-action-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding: 8px 15px;
    border-top: 1px solid #ccc;
    background: #f1f1f1;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  .pending-action-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pending-action-button:hover {
    background-color: #0056b3;
  }
  