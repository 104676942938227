/* src/components/FinancialStatements.css */

.nested-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.nested-table th, .nested-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: right;
}

.nested-table th {
    text-align: center;
}

.subtotal-row {
    background-color: #f9f9f9;
    font-weight: bold;
}

.nested-category {
    margin-left: 20px;
}

.nested-category h4 {
    margin-top: 20px;
}

.nested-category h3 {
    margin-top: 30px;
}

.nested-category h2 {
    margin-top: 40px;
}
