.dashboard {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  

  
  .reports-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .report-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .report-card h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
  
  .report-card button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .report-card button:hover {
    background-color: #0056b3;
  }
  