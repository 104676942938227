/* ClientFunding.css */
.funding-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .funding-container h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .funding-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .funding-table th,
  .funding-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .funding-table th {
    background-color: #f2f2f2;
  }
  