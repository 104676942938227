.notification-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow: hidden;
}

.notification-form {
  height: auto !important;
  flex-shrink: 0;
  /* padding: 10px; */
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.notification-table {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.notification-table table {
  width: 100%;
  border-collapse: collapse;
}

.notification-table th,
.notification-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.notification-table th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 1;
}

.notification-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

textarea {
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
  box-sizing: border-box;
}

.toggle-container {
  margin-bottom: 10px;
}

.toggle-container label {
  font-size: 1.2em;
}
