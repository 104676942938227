.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.top-bar {
  height: 50px; /* Adjust according to your top bar height */
  flex-shrink: 0;
}

.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden; /* Prevent unwanted scrolling */
}

/* Sidebar container (default) */
.sidebar-container {
  display: flex;
  height: calc(100vh - 50px); /* Adjust for top bar height */
  position: relative;
  transition: transform 0.3s ease;
  z-index: 100;
}

/* Sidebar styling */
.sidebar {
  background-color: #333;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  width: 250px; /* Sidebar width */
  overflow-y: auto; /* Allow scrolling */
  transition: transform 0.3s ease, width 0.3s ease;
}

/* Collapsed sidebar (unpinned) */
.sidebar-container.collapsed .sidebar {
  position: absolute; /* Overlay the content without pushing */
  transform: translateX(-250px); /* Move sidebar off-screen */
  z-index: 101; /* Ensure it's above content */
}

/* Sidebar when open via hover (unpinned state) */
.sidebar-container.open:not(.pinned) .sidebar {
  transform: translateX(0); /* Slide sidebar in */
  position: absolute; /* Sidebar overlays content */
  z-index: 101; /* Ensure it's above content */
}

/* Pinned sidebar (pushes content) */
.sidebar-container.pinned .sidebar {
  position: relative; /* Sidebar pushes content when pinned */
  transform: none; /* Ensure it doesn't translate or cause gaps */
}

/* Default content behavior (unpinned, no margin) */
.content-wrapper {
  transition: margin 0.3s ease;
  flex-grow: 1;
  margin-left: 0; /* No margin when unpinned */
}

/* Content pushed when sidebar is pinned */
.sidebar-container.pinned .content-wrapper {
  margin-left: 250px; /* Push content when sidebar is pinned */
}



.content {
  display: flex;
  flex: 1;
  padding: 0px 10px; /* Add bottom padding */
  box-sizing: border-box;
  overflow-y: auto; /* Allow scrolling of the content */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  max-height: calc(100vh - 50px); /* Adjust for the top bar height */
  width: 100%;
}

h1 {
  text-align: center;
}

.flexDiv {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 60px);
}

.form-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Reduce margin */
  position: sticky;
  background-color: #fff;
  z-index: 10;
  flex-direction: row;
  width: 100%;
  display: flex;
	gap: 5px;
}

.full-width {
	flex-basis: 100%
}

.form-element-full-width {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-buttons {
  display: flex;
  gap: 10px;
}

form button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

form button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.table-header {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-container {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  margin-bottom: 70px; /* Add margin to prevent overlap with buttons */
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  align-self: flex-start; /* Prevent the table from stretching to the full height */
}

table th {
  text-align: left;
  background-color: #f2f2f2;
  color: black;
  position: sticky;
  top: 0px; /* Adjust for TopBar and form header height */
  z-index: 5;
}

table tr:nth-child(even) {
  background: #f8f8f8;
}

th, td {
  border: 1px solid #ddd;
  padding: 0px 5px;
}

input[type="text"], input[type="file"], input[type="email"], input[type="number"], input[type="date"], input[type="datetime-local"], input[type="search"], select {
  padding: 0em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 0em;
  margin-bottom: 0em;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  height: 35px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 20px 20px 20px; /* Add padding to pagination */
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:not(:disabled):hover {
  background-color: #0056b3;
}

.pagination span {
  padding: 5px 10px;
}

.pagination-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 5px;
  height: 100% !important;
}

.pagination-form input {
  display: flex;
  width: 60px;
  padding: 5px;
  margin-right: 5px;
}

.pagination-form button {
  display: flex;
  padding: 5px 10px;
  cursor: pointer;
}

.fixed-form-buttons {
  display: flex;
  justify-content: flex-end;  
  gap: 10px;
  padding: 10px;
}

.input-error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8em;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #0056b3;
}

.button-container {
  display: flex;
  justify-content: flex-start; /* Align buttons to the left */
  gap: 10px; /* Add some space between buttons */
}