/* Home.css */
.home-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .home-container h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .home-container p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .info-section {
    margin-bottom: 30px;
  }
  
  .info-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .resources-section {
    margin-bottom: 30px;
  }
  
  .resources-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .resources-section ul {
    list-style: none;
    padding: 0;
  }
  
  .resources-section ul li {
    margin-bottom: 10px;
  }
  
  .resources-section ul li a {
    color: #007bff;
    text-decoration: none;
  }
  
  .resources-section ul li a:hover {
    text-decoration: underline;
  }
  