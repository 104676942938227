/* ClientPayments.css */
.payments-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filters-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.applied-filters {
  margin-top: 10px;
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.filters-container label {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.filters-container input, .filters-container button {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.filters-container button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.filters-container button:hover {
  background-color: #0056b3;
}

.payments-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.payments-table th, .payments-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.payments-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.payments-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.payments-table tr:hover {
  background-color: #f1f1f1;
}
