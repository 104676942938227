.balance-sheet-manager {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.balance-sheet-manager h1 {
    text-align: center;
    margin-bottom: 20px;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.actions select,
.actions input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.actions button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.balance-sheet {
    margin-top: 20px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
