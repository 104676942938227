.income-statement-manager {
    padding: 20px;
}

.actions {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.multiple-actions {
    margin-top: 20px;
}

.year-checkboxes {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.income-statement-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.income-statement-table th,
.income-statement-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.income-statement-table th {
    background-color: #f2f2f2;
}

.error {
    color: red;
}
