/* CustomerDeposit.css */

.table-container-customer-deposit {
  margin-bottom: 0px !important;
}

.customer-deposit-container {
  padding: 0px 10px 0px;
  display: flex;
  position: relative;
}

.drawer-content {
  padding: 16px;
  background-color: #f7f7f7;
  border-radius: 8px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.drawer-collapsed .drawer-content {
  display: none;
}

.drawer-toggle {
  position: absolute;
  top: 20px;
  right: -20px;
  z-index: 1000;
  background-color: #1890ff;
  border: none;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.drawer-toggle-collapsed {
  right: -40px;
}

.drawer-toggle-flash {
  animation: flash 1s infinite alternate;
}

@keyframes flash {
  from {
    background-color: #1890ff;
  }
  to {
    background-color: #ff4d4f;
  }
}

.total-amounts {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.table-container-customer-deposit {
  margin-bottom: 16px;
}

.form-actions {
  display: flex;
  justify-content: flex-start;
}

.drawer-collapsed .fixed-form-buttons {
  left: 20px;
}
