/* src/components/CustomerDashboard.css */
.dashboard-container {
  padding: 20px;
}

.invoices-table {
  width: 100%;
  border-collapse: collapse;
}

.invoices-table th,
.invoices-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.invoices-table th {
  background-color: #f2f2f2;
  text-align: left;
  cursor: pointer;
}

.invoices-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.invoices-table tr:hover {
  background-color: #ddd;
}

