/* ClientDashboard.css */
.dashboard-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-container h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .metrics {
    display: flex;
    justify-content: space-around;
  }
  
  .metric {
    text-align: center;
  }
  
  .metric h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .metric p {
    font-size: 20px;
    font-weight: bold;
  }
  