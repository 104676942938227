.tabs {
    display: flex;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1em;
  }
  
  .tab {
    padding: 0.5em 1em;
    cursor: pointer;
    border: 1px solid transparent;
    border-bottom: none;
    background: #007bff;
    color: white;
    transition: background 0.3s, border 0.3s;
  }
  
  .tab:hover {
    background: #0056b3;
  }
  
  .tab.active {
    border-color: #007bff;
    border-bottom-color: white;
    background: white;
    color: #007bff;
    font-weight: bold;
  }
  
  .tab-content {
    flex-grow: 1; /* Allow this section to take up remaining space */
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  
  .graph-container {
    flex-grow: 1; /* Ensures the graph takes up all available space */
    height: 100%; /* Ensure full height */
    width: 100%;  /* Ensure full width */
    margin-top: 20px;
  }
    
  .collapsible {
    margin-top: 1em;
  }
  
  .collapsible-header {
    padding: 0.5em 1em;
    cursor: pointer;
    background: #007bff;
    color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 0.5em;
  }
  
  .collapsible-content {
    display: block;
    padding: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: white;
  }
  
  .collapsible-content ul {
    list-style: none;
    padding: 0;
  }
  
  .collapsible-content li {
    margin-bottom: 0.5em;
  }
  
  .collapsible-content li div {
    margin-top: 0.5em;
  }
  

