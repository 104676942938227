/* Support.css */
.support-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .support-container h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .support-container p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .support-section {
    margin-bottom: 30px;
  }
  
  .support-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .support-section a {
    color: #007bff;
    text-decoration: none;
  }
  
  .support-section a:hover {
    text-decoration: underline;
  }
  