/* components/GenericMetadata.css */
.metadata-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
    padding: 0;
    margin: 0; /* Ensure no extra margin */
  }
  
  .metadata-modal {
    position: relative;
    background: white;
    border-radius: 8px;
    width: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Added to prevent overflow */
    padding: 0; /* Ensure no extra padding */
    margin: 0; /* Ensure no extra margin */
    outline: none;
  }
  
  .metadata-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background: #f1f1f1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .metadata-body {
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .metadata-content h2 {
    margin: 0;
  }
  
  .metadata-content button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0; /* Ensure no extra padding */
    margin: 0; /* Ensure no extra margin */
  }
  