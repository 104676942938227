/* GettingStarted.css */
.getting-started-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .getting-started-container h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .getting-started-container p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  .section h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
/* Add these styles to your GenericSearch.css file */

.generic-table {
  width: 100%;
  margin: 10px 20px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box; /* Ensure padding is included in the table's width */
}

.generic-table th, .generic-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.generic-table th {
  background-color: #f9f9f9;
}

.generic-table-row {
  cursor: pointer;
}

.generic-table-row:hover {
  background-color: #f1f1f1;
}

.view-toggle {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}
