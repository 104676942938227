.token-expiry-indicator {
    position: relative; /* No absolute positioning */
    width: 50px;  /* Smaller size for TopBar */
    height: 50px;
}

.base-timer {
    position: relative;
    width: 50px;
    height: 50px;
}

.base-timer__svg {
    transform: scaleX(-1); /* Makes the animation move left to right */
}

.base-timer__circle {
    fill: none;
    stroke: none;
}

.base-timer__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
}

.base-timer__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    stroke: currentColor;
}

.base-timer__path-remaining.green {
    color: green;
}

.base-timer__path-remaining.orange {
    color: orange;
}

.base-timer__path-remaining.red {
    color: red;
}

.base-timer__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the label */
    font-size: 12px; /* Smaller font size */
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.base-timer__path-elapsed, .base-timer__path-remaining {
    stroke-width: 5px; /* Adjust stroke width to fit smaller size */
}
