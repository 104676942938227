/* src/components/PurchaseOrderDetails.css */

.purchase-order-details {
    padding: 20px;
  }
  
  .purchase-order-details h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .purchase-order-details p {
    font-size: 16px;
    margin: 10px 0;
  }
