/* GenericSearch.css */
.search-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.search-modal-content {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%; /* Increase the width */
  height: calc(100vh - 60px);
  position: relative;
  padding: 0; /* Remove padding */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
  background: #f1f1f1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal-header h1 {
  font-size: 18px; /* Smaller title size */
  margin: 0;
}

.modal-header button {
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.modal-header button:hover {
  background-color: #e81123;
  color: #fff;
  border-radius: 4px;
}

.modal-header button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 120, 215, 0.5);
}

.generic-search {
  padding: 0; /* Remove padding */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.search-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 20px 10px 20px; /* Add padding to search fields */
}

.search-fields > div {
  flex: 1 1 200px;
  min-width: 200px;
}

.generic-results {
  list-style-type: none;
  padding: 0 20px 0 20px;
}

.generic-result-item {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.generic-result-item:hover {
  background-color: #f9f9f9;
}

.generic-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  flex: 1;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.inline-search {
  position: relative;
}

.inline-search-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.inline-search-container .inline-search {
  flex-grow: 1;
}

.inline-search input {
  width: calc(100% - 40px); /* Adjust width to make space for the button */
  padding: 10px;
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px; /* Add border radius */
  box-sizing: border-box;
}

.inline-search-button {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0; /* Remove any padding */
  margin-top: 0px;
}

.inline-search-button i {
  font-size: 1.0em;
  color: #007bff;
}

.inline-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%; /* Position it right below the input field */
  left: 0;
  width: auto;
  min-width: 300px;
  max-width: 600px;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;
  display: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better visibility */
}

.inline-results.visible {
  display: block; /* Show when visible */
}

.inline-result-item {
  padding: 8px;
  cursor: pointer;
}

.inline-result-item:hover,
.inline-result-item.highlighted {
  background-color: #f0f0f0; /* Highlight color */
}

.no-results, .searching {
  padding: 10px;
  text-align: center;
  color: #999;
}

/* For overlay inside modal */
.modal-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Above modal content but below modal header */
}

.table-container.disabled {
  pointer-events: none; /* Prevents interaction */
  opacity: 0.5; /* Optional: reduces opacity to show it's inactive */
}

.page-loading-overlay, .modal-loading-overlay {
  position: absolute; /* Adjusted to absolute to cover only the content area */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100; /* Ensure it's above content but below the TopBar */
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure it's above the table but below the top bar */
}

/* Disable table interactions when loading */
.table-container.disabled {
  pointer-events: none; /* Disable clicks */
  opacity: 0.6; /* Visual indication that the table is inactive */
}