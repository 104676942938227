.select-payee-type-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .select-payee-type-container h2 {
    margin-bottom: 20px;
  }
  
  .button-group {
    display: flex;
    gap: 20px;
  }
  
  .payee-type-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
  }
  
  .payee-type-button:hover {
    background-color: #0056b3;
  }
  