.supplier-details-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .supplier-details {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  
  .supplier-info, .supplier-payment-options, .supplier-notes, .supplier-contacts, .documents-section, .metadata-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .supplier-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .supplier-actions button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .supplier-actions button:hover {
    background-color: #0056b3;
  }
  
  .documents-table-container {
    max-height: 400px;
    overflow-y: auto;
  }
  
  .documents-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .documents-table th,
  .documents-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .documents-table th {
    background-color: #f2f2f2;
  }
  
  .documents-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .documents-table button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: #fff;
    cursor: pointer;
  }
  
  .documents-table button:hover {
    background-color: #218838;
  }
  
  .payment-option-group {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .remove-payment-option-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .remove-payment-option-button:hover {
    opacity: 0.9;
  }
  
  .sliding-scale-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .sliding-scale-table th,
  .sliding-scale-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .sliding-scale-table th {
    background-color: #f2f2f2;
  }
  
  .sliding-scale-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  