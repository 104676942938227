.payroll-input {
    margin: 20px;
  }
  
  h2 {
    text-align: center;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
  }
  
  tfoot td {
    font-weight: bold;
  }
  
  .beige {
    background-color: #f5f5dc;
  }
  
  .blue {
    background-color: #add8e6;
  }
  
  .summary {
    margin-top: 20px;
  }
  
  