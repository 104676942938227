/* EnterPaymentDetails.css */

.enter-payment-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .enter-payment-details label {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .enter-payment-details select,
  .enter-payment-details input {
    padding: 5px;
    font-size: 1em;
  }
  
  .enter-payment-details button {
    align-self: flex-start;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .enter-payment-details button:hover {
    background-color: #45a049;
  }
  