.line-items-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .line-items-table th,
  .line-items-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .line-items-table th {
    background-color: #f2f2f2;
  }
  
  .line-items-table tfoot td {
    font-weight: bold;
  }
  