.accounts-payable-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 255px); 
  overflow: auto;
}

.accounts-payable-table-container {
  flex-grow: 1;
  overflow-y: auto;
}

.accounts-payable-table {
  width: 100%;
  border-collapse: separate;
}

.accounts-payable-table th,
.accounts-payable-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.accounts-payable-table th {
  background-color: #f2f2f2;
}

.accounts-payable-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.accounts-payable-table tbody tr:hover {
  background-color: #f1f1f1;
}

.accounts-payable-table th {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #4CAF50;
  color: white;
}

.accounts-payable-table tbody tr.selected {
  background-color: #d1e7dd;
}

/* New styles for Payment History table */
.payment-history-container-2 {
  display: none;
  height: calc(75vh - 55px);
  overflow-y: auto;
}

.payment-history-container-2.visible {
  display: block;
}

.toggleable-header {
  cursor: pointer;
  color: #4CAF50;
  margin: 20px 0 10px;
  font-size: 1.2rem;
}

.toggleable-header:hover {
  text-decoration: underline;
}
