.factoring-rate-component {
  margin-bottom: 20px;
}

.rate-type-selection {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.rate-type-selection label {
  font-weight: bold;
}

.flat-rate label,
.sliding-scale-row label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.sliding-scale h4 {
  margin-bottom: 10px;
}

.sliding-scale-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.sliding-scale-table th,
.sliding-scale-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.sliding-scale-table th {
  background-color: #f2f2f2;
}

.sliding-scale-table input {
  width: 100%;
  text-align: center;
}
