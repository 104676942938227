.review-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .review-navigation button {
    padding: 10px;
    font-size: 16px;
  }
  
  .review-payments {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .payment-review {
    margin-bottom: 20px;
  }
  