.add-client-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .add-client-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .add-client-form .form-group {
    margin-bottom: 15px;
  }
  
  .add-client-form .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .add-client-form .form-group input,
  .add-client-form .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .add-client-form .form-group textarea {
    resize: vertical;
  }
  
  .contact-group {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .add-client-form .add-contact-button,
  .add-client-form .remove-contact-button,
  .add-client-form .submit-button {
    display: inline-block;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .add-client-form .remove-contact-button {
    background-color: #dc3545;
  }
  
  .add-client-form .add-contact-button {
    background-color: #28a745;
  }
  
  .add-client-form .submit-button {
    background-color: #007bff;
    width: 100%;
  }
  
  .add-client-form .add-contact-button:hover,
  .add-client-form .remove-contact-button:hover,
  .add-client-form .submit-button:hover {
    opacity: 0.9;
  }
  
  .payment-option-group {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .remove-payment-option-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .remove-payment-option-button:hover {
    opacity: 0.9;
  }
  