/* PurchaseOrderSearch.css */
.employee-search {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.employee-search h2 {
  margin-bottom: 20px;
}

.selected-entity {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.selected-entity h3 {
  margin-top: 0;
}

.selected-entity p {
  margin: 5px 0;
}
