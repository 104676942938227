/* src/components/Navigation.css */

/* Styling for sidebar links and list items */
.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  color: #fff;
  text-decoration: none;
}

.sidebar a.active {
  font-weight: bold;
  text-decoration: underline;
}

.sidebar a:hover {
  text-decoration: underline;
}

/* Optional: For submenus (if applicable) */
.sidebar li ul {
  padding-left: 20px;
  display: none; /* Hidden by default */
}

.sidebar ul li ul.expanded {
  display: block; /* Expand submenu */
}

/* Optional: Styling for interactive div elements */
.sidebar div {
  cursor: pointer;
  font-weight: bold;
}

.sidebar div:hover {
  text-decoration: underline;
}

/* Optional: Custom scrollbar styling */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-track {
  background: #333;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #333;
}
